import React, { useState } from 'react'
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

import Layout from '../../components/Layout'

const GalleryPage = ({
    data: {
        allInstaNode: {
            edges
        }
    }
}) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (event, obj) => {
        console.log('clicked!', obj);
        setCurrentImage(obj.index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const photos = edges.map(({node: {localFile: { childImageSharp }}}) => {
        return childImageSharp.fixed;
    })

    const views = photos.map(x => ({
        ...x, srcset: x.srcSet,
    }))

    return (
        <Layout>
            <section className="section">
                <div className="container">
                    <div className="content">
                        <Gallery photos={photos} onClick={openLightbox}/>
                        <ModalGateway>
                            { viewerIsOpen ? (
                                <Modal onClose={closeLightbox}>
                                    <Carousel
                                        currentIndex={currentImage}
                                        views={views}
                                    />
                                </Modal>
                            ) : null}
                        </ModalGateway>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default GalleryPage

export const galleryPageQuery = graphql`
query GalleryQuery {
    allInstaNode {
        edges {
            node {
                id
                localFile {
                    childImageSharp {
                        fixed(width: 300, height: 300) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    }
}
`
